import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import SEO from '../../components/atoms/Seo'
import Navbar from '../../components/organisms/Navbar'
import Footer from '../../components/organisms/NewFooter'

const TITLE = 'Aquésition indépendants'
const DESCRIPTION = 'Aquésition indépendants'

const Title = styled.div`
  margin: 100px 24px 50px 24px;
`

const IndependantAcquisition = ({ location }) => (
  <>
    <SEO
      title={TITLE}
      location={location}
      description={DESCRIPTION} />
    <Title>Page 2</Title>
    <Navbar location={location} />
    <Footer noImage={true} noForm={true} />
  </>
)

IndependantAcquisition.propTypes = {
  location: PropTypes.object.isRequired
}

export default IndependantAcquisition
